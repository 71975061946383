import React from "react";
import { Link, useLocation } from "react-router-dom";
import DOMPurify from "dompurify";
import useTranslation from "../../utils/hooks/use-translation";
import { CounterFloodLight } from "../../utils/helpers";

const SectionHeader = (props) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const {
    title,
    subTitle,
    linkText,
    linkUrl = "",
    link,
    floodLightIds,
    containerClass,
    headerClass,
    titleClass,
    subTitleClass,
    variant,
    btnClass,
    handleFilter,
    filter,
    as,
    isHeader,
    action,
    sectionId,
  } = props;
  const isHeaderAvailble = isHeader !== undefined ? isHeader : true;
  return (
    <>
      {isHeaderAvailble && !pathname.includes("karaoke_recording/Video") && (
        <header className={`s-header ${headerClass}`}>
          <div className={`container-fluid ${containerClass} ${sectionId}`}>
            {title && (
              <>
                <h3
                  className={`s-title ${titleClass}`}
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(title),
                  }}
                ></h3>
              </>
            )}
            {subTitle && (
              <h4
                className={`s-sub-title ${subTitleClass}`}
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(subTitle),
                }}
              ></h4>
            )}
            {linkText &&
              (action ? (
                <Link
                  variant={variant}
                  as={as}
                  className={`btn-link ${btnClass}`}
                  to={linkUrl}
                  onClick={() => action()}
                >
                  <span>{linkText}</span>
                </Link>
              ) : (
                <Link
                  variant={variant}
                  as={as}
                  className={`btn-link ${btnClass}`}
                  to={link}
                  onClick={() =>
                    floodLightIds && CounterFloodLight(floodLightIds)
                  }
                >
                  <span>{linkText}</span>
                  <span className="icon icon-Right icon-Right-arrow-01"></span>
                </Link>
              ))}

            {handleFilter && (
              <select
                id="filter-dropdown"
                className="select mt-1 mt-md-7"
                value={filter}
                onChange={(e) => handleFilter(e.target.value)}
              >
                <option value="all">{t.common.all}</option>
                <option value="most_popular">{t.common.most_popular}</option>
                <option value="most_viewed">{t.common.most_viewed}</option>
                <option value="most_liked">{t.common.most_liked}</option>
              </select>
            )}
          </div>
        </header>
      )}
    </>
  );
};
export default SectionHeader;
