import React, { useEffect, useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { addressValidationSchema } from "../../../utils/validations";
import {
  getAddressByUserId,
  addUpdateAddress,
  deleteAddress,
  setAsDefaultAddress,
  getCityStateByPinCode,
  getStateList,
  getCityList,
  getProfile,
} from "../../../services";
import useLoader from "../../../utils/hooks/use-loader";
import toast from "react-hot-toast";
import AppModal from "../../modals";
import { useDispatch } from "react-redux";
import { setDefaultAddress } from "../../../store/slices/common-slice";
import useTranslation from "../../../utils/hooks/use-translation";
import {
  getCDSPixelId,
  getlanguageId,
  isClaimFormWindowOpen,
  showApiErrors,
} from "../../../utils/helpers";
import { Link } from "react-router-dom";
import CommonFooter from "../../footer/CommonFooter";
import {
  handleKeyPressMobileNumber,
  handleKeyPressPincode,
} from "../../../utils/helpers";
import useSuccessHandler from "../../../utils/hooks/use-success-model";
import useAuth from "../../../utils/hooks/use-auth";
import { setUser } from "../../../store/slices/auth-slice";
import { isTamil } from "../../../utils/helpers";

const AddressForm = ({ source }) => {
  const { userDetails } = useAuth();
  const { handleSuccess } = useSuccessHandler();
  const { t, currentLanguage } = useTranslation();
  let addressValidation = addressValidationSchema(t);
  const dispatch = useDispatch();
  const [openAddressModel, setAddressModel] = useState(false);
  const [addressList, setAddressList] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState("");
  const { toggleLoader } = useLoader();
  const initialValues = {
    AddressOne: "",
    AddressTwo: "",
    Near_Landmark: "",
    Pincode: "",
    State: "",
    City: "",
    Set_as_Default: addressList.length === 0,
    shippingMobile: "",
  };
  const [intialData, setInitialData] = useState(initialValues);
  const [cityStateData, setCityStateData] = useState();
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);

  //Initiall call and load address list
  useEffect(() => {
    getAllAddressByUserId();
    getAllStateList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // list of form inputs to show
  const renderAddressInput = [
    {
      type: "text",
      name: "AddressOne",
      placeHolder: `${t.common.address_line1}*`,
      onInput: () => {},
    },
    {
      type: "text",
      name: "AddressTwo",
      placeHolder: t.common.address_line2,
      onInput: () => {},
    },
    {
      type: "text",
      name: "Near_Landmark",
      placeHolder: t.common.nearest_landmark,
      onInput: () => {},
    },
    {
      type: "tel",
      name: "shippingMobile",
      placeHolder: t.common.alternate_mobile_number,
      onInput: (e) => {
        handleKeyPressMobileNumber(e);
      },
    },
    {
      type: "text",
      inputType: "number",
      name: "Pincode",
      placeHolder: `${t.common.pincode}*`,
      onInput: (e) => {
        handleKeyPressPincode(e);
      },
    },
    {
      //type: 'text',
      type: "select",
      name: "State",
      placeHolder: `${t.common.state}*`,
      //disabled: true,
      options: cityStateData
        ? [{ label: cityStateData.state, value: cityStateData.stateId }]
        : stateList
        ? stateList
        : [],
    },
    {
      //type: 'text',
      type: "select",
      name: "City",
      placeHolder: `${t.common.city}*`,
      //disabled: true,
      options: cityStateData
        ? [{ label: cityStateData.city, value: cityStateData.cityId }]
        : cityList
        ? cityList
        : [],
    },

    {
      type: "checkbox",
      name: "Set_as_Default",
      placeHolder: t.common.set_as_default,
      inputType: "checkBox",
      heading: t.common.set_as_default,
    },
  ];

  //get all state list
  const getAllStateList = async () => {
    try {
      const res = await getStateList(1);
      if (res.status === 200 && res.data.succeeded) {
        let resdata = res.data && res.data.data;
        if (resdata && resdata.length) {
          const transformedData = resdata.map(({ id, name }) => ({
            label: name,
            value: id,
          }));
          setStateList(transformedData);
        }
      }
    } catch (err) {}
  };

  //get all city list
  const getStateWiseCityList = async (stateID) => {
    try {
      const res = await getCityList(stateID);
      if (res.status === 200 && res.data.succeeded) {
        let resdata = res.data && res.data.data;
        if (resdata && resdata.length) {
          const transformedData = resdata.map(({ id, name }) => ({
            label: name,
            value: id,
          }));
          setCityList(transformedData);
        }
      }
    } catch (err) {}
  };

  //get all home page details
  const getAllAddressByUserId = async () => {
    toggleLoader(true);
    try {
      const res = await getAddressByUserId();
      if (res.status === 200 && res.data.succeeded) {
        let resdata = res.data && res.data.data;
        const defaultAddress =
          resdata &&
          Array.isArray(resdata) &&
          resdata.length !== 0 &&
          resdata.find((obj) => obj.isDefault);
        setAddressList(resdata);
        dispatch(setDefaultAddress(defaultAddress));
      }
    } catch (err) {
    } finally {
      toggleLoader(false);
    }
  };

  const getUserDetails = async () => {
    toggleLoader(true);
    try {
      let reqData = {
        userId: userDetails && userDetails.id,
      };
      const res = await getProfile(reqData);
      if (res.status === 200) {
        let data = res.data && res.data;
        dispatch(setUser(data));
        if (data && data.isprofileComplete) {
          // ga event fire ones profile is successfully completed
          if (isTamil()) {
            window.dataLayer.push({ event: "CSTamil24_CompletedProfile" });
          } else {
            window.dataLayer.push({ event: "CSBharat24_CompletedProfile" });
          }
        }
      }
    } catch (err) {
    } finally {
      toggleLoader(false);
    }
  };

  //handle address submit
  const handleSubmit = async (values) => {
    try {
      const hfSessionKey = await getCDSPixelId();
      const langId = getlanguageId(currentLanguage);
      let reqData = {
        id: values.id || 0,
        address1: values.AddressOne,
        address2: values.AddressTwo,
        NearestLandmark: values.Near_Landmark,
        pincode: values.Pincode,
        pinCodeId: values.pincodeId,
        cityId: values.cityId,
        stateId: values.stateId,
        isDefault: values.Set_as_Default || false,
        isActive: true,
        nearestLandmark: values.Near_Landmark,
        shippingMobile: values.shippingMobile,
        hfSession: hfSessionKey || "",
        languageId: langId,
      };
      const res = await addUpdateAddress(reqData);
      if (res.data && res.data.succeeded) {
        let resData = res.data && res.data.data;
        let claimableRewardId =
          resData?.rewardId !== 1 && resData?.canClaim === true
            ? resData?.rewardId
            : null;
        // let claimableRewardId = resData?.rewardId !== 1 && resData?.canClaim === true ? resData?.rewardId : null;
        // claimableRewardId && handleCLaimWindow(claimableRewardId, langId)
        // handleSuccess(resData, 'address');
        if (claimableRewardId) {
          isClaimFormWindowOpen(claimableRewardId, langId).then((result) => {
            handleSuccess(resData, "address", result);
          });
        } else {
          resData?.canClaim === false && handleSuccess(resData, "address");
        }
        getAllAddressByUserId();
        getUserDetails();
        onClose();
      } else {
        showApiErrors(res);
      }
    } catch (err) {
      let errorRes = err.response && err.response.data;
      toast.error(errorRes.message);
    } finally {
      toggleLoader(false);
    }
  };

  //handle pin code changes city and state will populate based on pincode
  const handlePincodeChange = async (event, setFieldValue, setFieldError) => {
    const pincodeValue = event.target.value;
    // Add your logic here for handling pincode change
    try {
      const res = await getCityStateByPinCode(pincodeValue);
      if (res.data && res.data.succeeded) {
        let data = res.data && res.data.data;
        if (data) {
          setCityStateData(data);
          //setFieldValue('City', data ? data.city : '');
          //setFieldValue('State', data ? data.state : '');
          setFieldValue("cityId", data ? data.cityId : "");
          setFieldValue("stateId", data ? data.stateId : "");
          setFieldValue("pincodeId", data ? data.pincodeId : "");
          setFieldValue("Pincode", pincodeValue ? pincodeValue : "");
        } else {
          setFieldError("Pincode", "This pin code is not serviceable");
          setFieldValue("City", "");
          setFieldValue("State", "");
          setFieldValue("cityId", "");
          setFieldValue("stateId", "");
        }
      }
    } catch (err) {}
    // You can update the state or perform any other actions based on the pincode change
  };

  const getPincodeWiseCityState = async (pincodeValue) => {
    try {
      const res = await getCityStateByPinCode(pincodeValue);
      if (res.data && res.data.succeeded) {
        let data = res.data && res.data.data;
        if (data) {
          setCityStateData(data);
        } else {
          setCityStateData("");
        }
      }
    } catch (err) {}
    // You can update the state or perform any other actions based on the pincode change
  };

  //render address form fields
  const renderAddressForm = () => {
    return (
      <Formik
        initialValues={intialData}
        validationSchema={addressValidation}
        onSubmit={handleSubmit}
      >
        {({ values, setFieldValue, touched, errors, setFieldError }) => (
          <Form autoComplete="off">
            <>
              <div className=" form-address" style={{ borderRadius: "1rem" }}>
                {renderAddressInput &&
                  renderAddressInput.map((field, indexing) => {
                    const isChecked = values && values?.Set_as_Default;
                    return (
                      <>
                        {
                          <div key={indexing} className="form-group">
                            {field.type === "checkbox" ? (
                              <>
                                <div className={"mt-4"}>
                                  <Field
                                    id={"check-box"}
                                    type="checkbox"
                                    name={`Set_as_Default`}
                                    className={`form-check-input ml-0 form-check ${
                                      touched[field.name] && errors[field.name]
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    checked={isChecked}
                                    placeHolder={field.placeHolder}
                                  />
                                  <label htmlFor={"check-box"}>
                                    {t.common.set_as_default}
                                  </label>
                                </div>
                              </>
                            ) : field.type === "select" ? (
                              <Field
                                as="select"
                                name={field.name}
                                className={`form-select form-custom-select bg-grey ${
                                  touched[field.name] && errors[field.name]
                                    ? "is-invalid"
                                    : ""
                                }`}
                                placeHolder={field.placeHolder}
                                value={values[field.name]}
                                disabled={field.disabled ? true : false}
                                onBlur={() => {
                                  if (field.name === "State") {
                                    getStateWiseCityList(values[field.name]);
                                  }
                                }}
                              >
                                <option value="">{field.placeHolder}</option>
                                {field.options.map((el, optionIndex) => (
                                  <option key={optionIndex} value={el.value}>
                                    {el.label}
                                  </option>
                                ))}
                              </Field>
                            ) : (
                              <>
                                {field.label && (
                                  <label htmlFor={field.name}>
                                    {field.label}
                                  </label>
                                )}
                                <Field
                                  type={field.type}
                                  name={field.name}
                                  className={`form-control bg-grey ${
                                    touched[field.name] && errors[field.name]
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  disabled={field.disabled}
                                  onBlur={async (e) =>
                                    field.name === "Pincode" &&
                                    handlePincodeChange(
                                      e,
                                      setFieldValue,
                                      setFieldError
                                    )
                                  }
                                  placeHolder={field.placeHolder}
                                  onInput={field.onInput}
                                />
                              </>
                            )}
                            <ErrorMessage
                              name={field.name}
                              className="text-danger"
                              component="div"
                            />
                          </div>
                        }
                      </>
                    );
                  })}
                <button type="submit" className="btn btn-dark btn-block">
                  {t.common.save_address}
                </button>
              </div>
            </>
          </Form>
        )}
      </Formik>
    );
  };

  //handle delete address api call
  const deleteAddressApi = async (id) => {
    toggleLoader(true);
    try {
      let reqData = { id: id, languageId: getlanguageId(currentLanguage) };
      const res = await deleteAddress(reqData);
      if (res.status === 200 && res.data.succeeded) {
        getAllAddressByUserId();
      } else {
        toast.error(res.data && res.data.message);
      }
    } catch (err) {
    } finally {
      toggleLoader(false);
    }
  };

  //handle checkbox change and set as default action api call
  const handleCheckBoxChange = async (e, id) => {
    toggleLoader(true);
    try {
      let reqData = { id: id };
      const res = await setAsDefaultAddress(reqData);
      if (res.status === 200 && res.data.succeeded) {
        getAllAddressByUserId();
      } else {
        toast.error(res.data && res.data.message);
      }
    } catch (err) {
    } finally {
      toggleLoader(false);
    }
  };

  //render address list which is already added
  const renderAddress = () => {
    if (addressList && addressList.length !== 0) {
      return addressList.map((el, i) => {
        const isChecked = el && el?.isDefault;
        return (
          <div
            className={
              source === "profile"
                ? "address-card address-full"
                : "address-card"
            }
            index={i}
          >
            <div className="address-left">
              <p>
                {el.address1 && `${el.address1}`}
                {el.address2 && `, ${el.address2}`}
                <br />
                {el.nearestLandmark && `${el.nearestLandmark}`}
                {el.cityName &&
                  (el.nearestLandmark ? `, ${el.cityName}` : `${el.cityName}`)}
                <br />
                {el.stateName && `${el.stateName}`}
                {el.pincode && ` ${el.pincode}`}
              </p>
              <p>{el.shippingMobile && `+91-${el.shippingMobile}`}</p>
              <div className={"mt-4"}>
                <Field
                  id={`Set_as_Default${i}`}
                  type="radio"
                  name={`Set_as_Default`}
                  className={"form-check-input ml-0 form-check"}
                  onChange={(e) => {
                    handleCheckBoxChange(e, el.id);
                  }}
                  checked={isChecked}
                />
                <label
                  className={"default-address-label"}
                  htmlFor={`Set_as_Default${i}`}
                >
                  {t.common.set_as_default}
                </label>
              </div>
            </div>
            <div className="address-right">
              <Link
                to="#"
                className="icon icon-edit-01"
                onClick={() => {
                  const data = {
                    AddressOne: el ? el.address1 : "",
                    AddressTwo: el ? el.address2 : "",
                    Near_Landmark: el ? el.nearestLandmark : "",
                    Pincode: el ? el.pincode : "",
                    // State: el ? el.stateName : "",
                    // City: el ? el.cityName : "",
                    State: el ? el.stateId : "",
                    City: el ? el.cityId : "",
                    Set_as_Default: el ? el.isDefault : "",
                    cityId: el ? el.cityId : "",
                    stateId: el ? el.stateId : "",
                    pincodeId: el ? el.pinCodeId : "",
                    id: el ? el.id : "",
                    shippingMobile: el.shippingMobile,
                  };
                  getStateWiseCityList(el.stateId);
                  getPincodeWiseCityState(el.pincode);
                  setSelectedAddress(el);
                  setInitialData(data);
                  setAddressModel(true);
                }}
              ></Link>
              <Link
                to="#"
                className={`icon icon-delete-01 ${
                  el.isDefault ? "disabled" : ""
                }`}
                onClick={() => {
                  let id = el.id;
                  deleteAddressApi(id);
                }}
              ></Link>
            </div>
          </div>
        );
      });
    }
  };

  //handle address model close
  const onClose = () => {
    setAddressModel(false);
    setInitialData(initialValues);
    setSelectedAddress("");
  };

  return (
    <>
      <div
        className={`address-header ${
          source === "profile" ? "address-header-lg" : "mb-3"
        }`}
      >
        <h5 className="address-title">{t.common.address}</h5>
        <button
          type="button"
          className="form-add-btn address-add-btn btn-link"
          onClick={() => {
            setAddressModel(true);
          }}
          disabled={addressList && addressList.length === 3}
        >
          + {t.common.add}
        </button>
      </div>
      {renderAddress()}
      {openAddressModel && (
        <AppModal
          heading={
            selectedAddress ? t.common.update_address : t.common.add_address
          }
          show={openAddressModel}
          headerClass={`pl-3 modal-address`}
          onClose={onClose}
          bodyClass="py-4"
        >
          {renderAddressForm()}
          <CommonFooter />
          {/* <div className="row align-items-center mt-5">
            <div className="col-4 mr-0">
              <Link to={addPathSeparators("/terms_of_use")} className="border-right pr-3">
                {t.header.terms_and_condiotions}
              </Link>
              <Link to={addPathSeparators("/privacy_policy")} className="pl-3">
                {t.header.privacy_policy}
              </Link>
            </div>
            <div className="col-8 text-right">
              <p className="mb-0">
                {t.common.footer_heading}{' '}
                <img src="/assets/img/icons/dietary_symbol.svg" alt="" />
              </p>
            </div>
          </div> */}
        </AppModal>
      )}
    </>
  );
};

export default AddressForm;
