import { AxiosInstance } from "../utils/axios-helper/axios-helper";
import { API_END_POINT } from "../utils/constants/api-end-points";
import { version } from "../config";
import CryptoJS from "crypto-js";
import gluedin from "gluedin";

const authModule = new gluedin.GluedInAuthModule();
const userModule = new gluedin.GluedInUserModule();

//send otp
export const sendOtp = async (data) => {
  return await AxiosInstance.post(`${API_END_POINT.sendOtp}`, data);
};

//verify otp
export const verifyOTP = async (data) => {
  return await AxiosInstance.post(API_END_POINT.verifyOtp, data);
};

//register user
export const registerUser = async (data) => {
  return await AxiosInstance.post(`${API_END_POINT.registerUser}`, data);
};

export const getAvatarList = async () => {
  return await AxiosInstance.get(`${version}${API_END_POINT.avatarList}`);
};

export const validateReferCode = async (code) => {
  return await AxiosInstance.get(
    `${API_END_POINT.validateRefCode}?code=${code}`
  );
};

export const whatsupCheckAPI = async (mobile) => {
  return await AxiosInstance.get(
    `${version}${API_END_POINT.whatsupCheck}?phonenumber=${mobile}`
  );
};

export const gluedinLogin = async (reqData) => {
  const secretKey =
    "d20e13d13ad4f99febe19aa0fefbcab7f7c05f222b75cfe5d9a54ba20d715c31";
  const encrypt = async (text, secretKey) => {
    return CryptoJS.AES.encrypt(text, secretKey).toString();
  };
  let data = await encrypt(window.location.origin, secretKey);
  // const authLoginInput = new gluedin.GluedInAuthModule();
  const signInRequest = {
    email: reqData.email,
    password: reqData.password,
    autoCreate: true,
    accessToken: data,
  };
  await authModule.AuthRawData(signInRequest);
  const userConfigResponse = await userModule.getUserMetaIds();
  if (userConfigResponse.status === 200 || userConfigResponse.status === 201) {
    const following = userConfigResponse.data.result.following || [];
    localStorage.setItem("following", JSON.stringify(following));
  }
};
