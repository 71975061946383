const BHARAT_API_END_POINT = {
  sendOtp: "/identity/OTP",
  verifyOtp: "/identity/OTPLogin",
  registerUser: "/identity/register",
  getUserProfile: "/identity/GetUserDetail",
  updateProfile: "/identity/UpdateUserDetail",
  getAllQuestion: "/identity/GetallQuestion",
  addEditUserAnswer: "/identity/AddEditUserQuestion",
  updateAddEditUserQuestion: "/identity/AddEditUserQuestion",
  homeDetails: "/Home/GetByHomePage",
  homeDetailsAfterLogin: "/Home/GetHomePageByUser",
  addClaimData: "/ClaimForm/ClaimFormAddEdit",
  avatarList: "/Masters/GetAvatar",
  getAddressByUId: "/User/getaddressbyuserid",
  getAddressByAId: "/User/getaddressbyid",
  addEditAddress: "/User/addeditaddress",
  deleteAddress: "/User/deleteaddress",
  defaultAddress: "/User/setdefaultaddress",
  sendRefer: "/User/sendreferal",
  cityStateByPinCode: "/Geolocation/GetByPinCode",
  stateList: "/Geolocation/GetStateDDL",
  cityList: "/Geolocation/GetCityDDL",
  // getWalletData: '/Rewards/GetTierWiseRewardsByUser',
  getWalletData: "/Rewards/GetAllRewards",
  getMyWins: "/Rewards/GetAllMyWins",
  getAllWinner: "/Reports/BharatWinnerList",
  getRewardsHistory: "/Rewards/GetAllRewardsHistory",
  getAllArtist: "/Video/GetAllArtistList",
  faqDetails: "/Masters/GetFAQ",
  sendReferral: "/User/sendreferal",
  sendAgainReferral: "/User/resendreferal",
  getReferral: "/User/GetReferralList",
  getAllNotification: "/Notification/GetNotificationListPageingById",
  readNotification: "/Notification/ReadNotificationByID",
  readAllNotification: "/Notification/ReadAllNotification",
  deleteNotificationById: "/Notification/DeleteNotificationById",
  deleteAllNotification: "/Notification/DeleteAllNotification",
  getContest: "/Contest/GetAllContest",
  getAllBehindThescneData: "/Video/GetBehindScenes",
  getAllMelodies: "/Video/GetAllMelodies",
  getAllTreasures: "/Video/GetAllTreasure",
  getAllUGC: "/Video/GetAllUGCVideo",
  getAllTheSeasons: "/Video/GetSeasons",
  userViewedVedio: "/User/AddUserVideo",
  likeVedios: "/Video/SaveLikeVideoById",
  uniqueCode: "/MixCode/verify-mixcode",
  validateRefCode: "/identity/ValidateCode",
  verifyRefCode: "/User/VerifyRefCode",
  pendingReferalList: "/User/GetPendingReferralList",
  songDrop: `/Home/songdrop`,
  whatsupCheck: `/WhatsApp/CheckTnC`,
  jioSawan: "/ClaimForm/ClaimFormJiosaavnAddEdit",
  sendReminder: `/User/bulk-referal`,
  IsClaimWindowOpen: `/User/check-claimstatus`,
  ////---------------------karaoke-------------------------
  karaokeSongList: "/Karaoke/GetAllSong",
  uploadUserkaraoke: "/Karaoke/AddEditUserKaraoke",
  uploadFilekaraoke: "/Karaoke/AddUserFile",
  earnkaraokePoints: "/User/AddUserKaraoke",
  uploadKaroakeLarge:"/Karaoke/uploadlarge"
};

const TAMIL_API_END_POINT = {
  sendOtp: "/identity/OTP",
  verifyOtp: "/identity/OTPLogin",
  registerUser: "/identity/tamil-register",
  getUserProfile: "/identity/GetTamilUserDetail",
  updateProfile: "/identity/tamil-UpdateUserDetail",
  getAllQuestion: "/identity/GetallTamilQuestion",
  addEditUserAnswer: "/identity/AddEditTamilUserQuestion",
  updateAddEditUserQuestion: "/identity/AddEditTamilUserQuestion",
  homeDetails: "/TamilHome/GetByHomePage",
  homeDetailsAfterLogin: "/TamilHome/GetHomePageByUser",
  addClaimData: "/TamilClaimForm/TamilClaimFormAddEdit",
  avatarList: "/Masters/GetAvatar",
  getAddressByUId: "/TamilUser/getaddressbyuserid",
  getAddressByAId: "/TamilUser/getaddressbyid",
  addEditAddress: "/TamilUser/addeditaddress",
  deleteAddress: "/TamilUser/deleteaddress",
  defaultAddress: "/TamilUser/setdefaultaddress",
  sendRefer: "/TamilUser/sendreferal",
  cityStateByPinCode: "/Geolocation/GetByPinCode",
  stateList: "/Geolocation/GetStateDDL",
  cityList: "/Geolocation/GetCityDDL",
  // getWalletData: '/Rewards/GetTierWiseRewardsByUser',
  getWalletData: "/TamilRewards/GetAllTamilRewards",
  // getMyWins: '/TamilRewards/GetAllTamilRewards',
  getMyWins: "/Rewards/GetAllMyWins",
  getRewardsHistory: "/Rewards/GetAllRewardsHistory",
  getAllArtist: "/Video/GetAllArtistList",
  faqDetails: "/TamilMasters/GetFAQ",
  sendReferral: "/TamilUser/sendreferal",
  sendAgainReferral: "/TamilUser/resendreferal",
  getReferral: "/TamilUser/GetReferralList",
  getAllNotification: "/TamilNotification/GetNotificationListPageingById",
  readNotification: "/TamilNotification/ReadNotificationByID",
  readAllNotification: "/TamilNotification/ReadAllNotification",
  deleteNotificationById: "/TamilNotification/DeleteNotificationById",
  deleteAllNotification: "/TamilNotification/DeleteAllNotification",
  getContest: "/TamilContest/GetAllTamilContest",
  getAllBehindThescneData: "/Video/GetBehindScenes",
  getAllMelodies: "/Video/GetAllMelodies",
  getAllTreasures: "/Video/GetAllTreasure",
  getAllUGC: "/Video/GetAllUGCVideo",
  getAllTheSeasons: "/Video/GetSeasons",
  getAllWinner: "/Reports/TamilWinnerList",
  userViewedVedio: "/TamilUser/AddUserVideo",
  likeVedios: "/Video/SaveLikeVideoById",
  uniqueCode: "/TamilMixCode/verify-mixcode",
  validateRefCode: "/identity/ValidateCode",
  verifyRefCode: "/TamilUser/VerifyRefCode",
  pendingReferalList: "/TamilUser/GetPendingReferralList",
  songDrop: `/TamilHome/songdrop`,
  getQuiz: `/TamilRewards/quiz`,
  postQuizAnswer: `/TamilRewards/quiz-answer`,
  whatsupCheck: `/WhatsApp/CheckTnC`,
  sendReminder: `/User/bulk-referal`,
  ////---------------------karaoke-------------------------
  karaokeSongList: "/TamilKaraoke/TamilGetAllSong",
  uploadUserkaraoke: "/TamilKaraoke/AddEditUserKaraoke",
  uploadFilekaraoke: "/TamilKaraoke/AddUserFile",
  earnkaraokePoints: "/User/AddUserKaraoke",
  uploadKaroakeLarge:"/Karaoke/uploadlarge"
};

let siteType = window.location.pathname;
export const API_END_POINT = siteType?.includes("/tamil")
  ? TAMIL_API_END_POINT
  : BHARAT_API_END_POINT;
