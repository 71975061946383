import useTranslation from "./use-translation";
import { useNavigate } from "react-router-dom";
import usePopup from "./use-popup";
// import useLoader from './use-loader';
import { useDispatch } from "react-redux";
import { addPathSeparators, renderCopyIcon } from "../helpers";
import { ClickCDP } from "../cds/cdsHelper";
import { setCoinAnimation } from "../../store/slices/common-slice";
import { useRef } from "react";
import { Card } from "react-bootstrap";
import useAuth from "./use-auth";

const useSuccessHandler = () => {
  // const { currentLanguage } = useTranslation();
  const dispatch = useDispatch();
  const labelRef = useRef(null);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userDetails } = useAuth();
  const uniquecodeQuotaFinish =
    userDetails && userDetails?.uniquecodeQuotaFinish;
  // const langId = getlanguageId(currentLanguage) ? getlanguageId(currentLanguage) : null;
  // const [isClaimFormWindowActive, setIsClaimFormWindowActive] = useState(null)
  // const isClaimFormWindowActive = useSelector((state) => state?.common?.isClaimFormWindowActive);

  const { openUniqueCodePopup, openSuccessPopup, openStartQuizPopup } =
    usePopup();

  const handleSuccess = async (data, pageType, isClaimFormWindowActive) => {
    let contentType = "";
    // let claimableRewardId = data?.rewardId !== 1 ? data?.rewardId : null;
    // claimableRewardId && await isClaimFormWindowOpen(claimableRewardId, langId).then(result => {
    //   dispatch(setClaimFormWindowStatus(result))
    //   setIsClaimFormWindowActive(result)
    // });

    if (data && data.rewardId && data.enterNow) {
      //if user has not enter unique code
      contentType = {
        image: data.displayIcon
          ? data.displayIcon
          : "/assets/img/modal/iphone_icon.svg",
        heading: t.model_messages.almost_there,
        subHeading: data.displayMessage,
        timer: false,
        cta: t.common.code,
        disabled: uniquecodeQuotaFinish,
        timerContent: t.model_messages.enter_valid_unique_code_win,
        onAction: () => {
          openUniqueCodePopup(true);
        },
        extraContent: (
          <div>
            <p className="modal-notification pb-md-2 pb-1">
              {
                "Your Coke Coins have been adjusted per your reward tier qualification. Check the status of your reward/eligibility in My Earnings."
              }
            </p>
          </div>
        ),
      };
      await openSuccessPopup(true, contentType);
    } else if (
      (data && data.quotaOver) ||
      (data.rewardId && data.canClaim && !isClaimFormWindowActive)
    ) {
      // if (data && data.quotaOver || data.rewardId && data.canClaim && isClaimFormWindowActive === false) {
      //if daily quata is over
      contentType = {
        image: data.displayIcon
          ? data.displayIcon
          : "/assets/img/modal/iphone_icon.svg",
        heading: t.model_messages.you_so_close,
        subHeading: data.displayMessage,
        description: t.model_messages.come_back_tomorrow,
        extraContent: (
          <div>
            <p className="modal-notification pb-md-2 pb-1">
              {
                "Your Coke Coins have been adjusted per your reward tier qualification. Check the status of your reward/eligibility in My Earnings."
              }
            </p>
          </div>
        ),
      };
      await openSuccessPopup(true, contentType);
    } else if (data && data.isPromoCode) {
      let code = data.promoCode;
      let contentType = {
        image: data.displayIcon
          ? data.displayIcon
          : "/assets/img/modal/jio_sawan.svg",
        heading: t.model_messages.congratulations,
        subHeading: data.displayMessage
          ? data.displayMessage
          : "You have won a Premium Subscription to JioSaavn for 1 Month.",
        extraContent: data.isPromoCode && data.promoCode && (
          <div>
            <h5 className="text-muted">{"Here’s the code"}</h5>
            <div className="promo-code mb-2 mb-md-3 mt-2">
              <Card.Text
                className={`px-3 m-0`}
                ref={labelRef}
                style={{ minWidth: "150px" }}
              >
                {code ? code : "XXXX"}
              </Card.Text>
              {renderCopyIcon(labelRef)}
            </div>
            <p className="modal-notification pb-md-2 pb-1">
              {"Redeem by 31st October, 2024"}
            </p>
          </div>
        ),
        cta: data.isPromoCode && data.enterNow ? "Enter Now" : "Redeem Now",
        disabled: uniquecodeQuotaFinish,
        onAction: () => {
          if (data.isPromoCode && data.enterNow) {
            openUniqueCodePopup(true);
          } else {
            openSuccessPopup(false, contentType);
            window.open("https://www.jiosaavn.com/", "_blank");
          }
        },
      };
      await openSuccessPopup(true, contentType);
      // jioSawanActivity(data.rewardId, data);
    } else if (data && (data.watchVedio || data.startQuiz)) {
      //if user only wins coins
      contentType = {
        image: data.displayIcon
          ? data.displayIcon
          : "/assets/img/modal/csk_tickets.svg",
        heading: t.model_messages.almost_there,
        subHeading: data.displayMessage,
        timerContent: t.model_messages.answer_simple_question_win,
        cta: t.common.start_quiz,
        onAction: () => openStartQuizPopup(true, { rewardsId: data.rewardId }),
        extraContent: (
          <div>
            <p className="modal-notification pb-md-2 pb-1">
              {
                "Your Coke Coins have been adjusted per your reward tier qualification. Check the status of your reward/eligibility in My Earnings."
              }
            </p>
          </div>
        ),
      };
      await openSuccessPopup(true, contentType);
    } else if (
      data &&
      data.rewardId &&
      data.canClaim &&
      isClaimFormWindowActive
    ) {
      //if user has eligable to claim
      //needs to cross verify user is eligible to claim or not

      contentType = {
        image: data.displayIcon
          ? data.displayIcon
          : "/assets/img/modal/iphone_icon.svg",
        heading: t.model_messages.congratulations,
        subHeading: data.displayMessage,
        timer: true,
        cta: t.common.redeem_now, // Conditionally include cta property
        onAction: () => {
          openSuccessPopup(false, contentType);
          navigate(addPathSeparators(`/claim_form/${data.rewardId}`));
        },
        timerValue: data.claimTimer ? data.claimTimer : "",
        extraContent: (
          <div>
            <p className="modal-notification pb-md-2 pb-1">
              {
                "Your Coke Coins have been adjusted per your reward tier qualification. Check the status of your reward/eligibility in My Earnings."
              }
            </p>
          </div>
        ),
      };
      await openSuccessPopup(true, contentType);
    } else if (data && data.coins > 0 && !data?.canClaim) {
      //if user only wins coins
      contentType = {
        image: data.displayIcon
          ? data.displayIcon
          : "/assets/img/modal/right-icon.svg",
        heading: t.model_messages.congratulations,
        subHeading: ` ${t.messages.you_have_earned} ${data.coins} ${t.common.coke_coins}.`,
        cta: t.common.ok,
        showAnimation: true,
        onAction: () => {
          dispatch(setCoinAnimation(true));
          openSuccessPopup(false, contentType);
        },
      };
      await openSuccessPopup(true, contentType);
    } else {
      if (pageType === "my_profile") {
        navigate(addPathSeparators("/my_profile"));
      }
    }
    //getAllHomepageDetailsALogin(dispatch, toggleLoader);
    //getRewardListAPI(dispatch, toggleLoader);
  };

  const handleQuizErrorModel = (message, data) => {
    let contentType = "";
    if (data) {
      contentType = {
        image: "/assets/img/modal/right-icon.svg",
        heading: t.model_messages.congratulations,
        subHeading: message,
        timer: false,
        cta: t.model_messages.got_it,
        description: "",
        onAction: () => {
          openSuccessPopup(false, "");
        },
      };
      ClickCDP("Pass_Quiz");
    } else {
      contentType = {
        image: "/assets/img/modal/cross-icon.svg",
        heading: t.model_messages.ohh_no,
        subHeading: message,
        timer: false,
        cta: t.common.try_again,
        description: "",
        timerContent:
          data.attemptCount === 1
            ? t.model_messages.better_luck_next_time_two_chance
            : data.attemptCount === 2
            ? t.model_messages.better_luck_next_time_one_chance
            : null,
        onAction: () => {
          openSuccessPopup(false, "");
        },
      };
      ClickCDP("Fail_Quiz");
    }
    return contentType;
  };

  const handlePermissionBlockModel = async () => {
    const contentType = {
      image: "/assets/img/karaokeon/block_recording.svg",
      heading: t.karaoke.blocked_recording,
      subHeading: t.karaoke.check_settings,
      bodyClassName: "",
      imageClassName: "modal-block-image mb-md-4 mb-2 pt-6 pb-2",
    };
    await openSuccessPopup(true, contentType);
  };

  const handleUploadFailedModel = async () => {
    const contentType = {
      image: "/assets/img/karaokeon/error.svg",
      heading: t.karaoke.please_try_again,
      bodyClassName: "",
      cta: t.common.ok,
      onAction: () => {
        openSuccessPopup(false, contentType);
      },
    };
    await openSuccessPopup(true, contentType);
  };

  const handleKaroakeSuccessModel = async () => {
    let contentType = {
      image: "/assets/img/modal/karaoke-submission.svg",
      subHeading: t.karaoke.karaoke_submit_success,
      cta: t.model_messages.got_it,
      onAction: () => {
        openSuccessPopup(false, "", {});
        navigate(addPathSeparators("/KaraokesongList"));
      },
    };
    await openSuccessPopup(true, contentType);
  };

  // Add other functions or state as needed

  return {
    handleSuccess: handleSuccess,
    handleQuizErrorModel: handleQuizErrorModel,
    handlePermissionBlockModel: handlePermissionBlockModel,
    handleUploadFailedModel: handleUploadFailedModel,
    handleKaroakeSuccessModel: handleKaroakeSuccessModel,
    // Add other values or functions as needed
  };
};

export default useSuccessHandler;
