// Import the `createSlice` function from `@reduxjs/toolkit`
import { createSlice } from "@reduxjs/toolkit";

// Define the initial state for the loading slice
const initialState = {
  isLoading: false, // Indicates if the application is currently in a loading state
  ipDetails: null, // Stores the IP details
  currentLanguage: "en",
  homepageDetails: {
    sections: [],
    notificationCount: 0,
    coinCount: 0,
  },
  avatarList: [],
  defaultAddress: "",
  playVedio: { showVedio: "", id: "" },
  tourGuide: { showTourGuide: false },
  siteType: "bharat",
  allGluedinData: [],
  songDropData: { timer: "" },
  afterLoginAction: {
    redirectPath: "",
    action: "",
  },
  rewardDetails: [],
  showCoin: false,
  isClaimFormWindowActive: null,
};

// Create the loading slice using `createSlice`
const loadingSlice = createSlice({
  name: "common", // Name of the slice
  initialState, // Initial state defined above
  reducers: {
    // Reducer function for toggling the loading state
    toggleLoading: (state, actions) => {
      state.isLoading = actions?.payload ? actions.payload : !state.isLoading; // Toggle the loading state based on the payload of the action or invert the current loading state
    },
    // Reducer function for setting IP details
    setIpDetails: (state, actions) => {
      state.ipDetails = actions.payload; // Set the IP details to the payload of the action
    },
    setLanguage: (state, actions) => {
      state.currentLanguage = actions.payload; // Set the IP details to the payload of the action
    },
    setHomePageDetails: (state, actions) => {
      state.homepageDetails = { ...state.homepageDetails, ...actions.payload }; // act as cdp cache to send event only once
    },
    setfaqDetails: (state, actions) => {
      state.setfaqDetails = { ...state.setfaqDetails, ...actions.payload }; // act as cdp cache to send event only once
    },
    setTierDetails: (state, actions) => {
      state.setTierDetails = { ...state.setTierDetails, ...actions.payload }; // act as cdp cache to send event only once
    },
    setAvatarList: (state, actions) => {
      state.avatarList = { ...state.avatarList, ...actions.payload }; // act as cdp cache to send event only once
    },
    setDefaultAddress: (state, actions) => {
      state.defaultAddress = { ...state.defaultAddress, ...actions.payload }; // act as cdp cache to send event only once
    },
    setPlayVedio: (state, actions) => {
      state.playVedio = { ...state.playVedio, ...actions.payload }; // act as cdp cache to send event only once
    },
    showTourGuide: (state, actions) => {
      state.tourGuide = { ...state.tourGuide, ...actions.payload }; // act as cdp cache to send event only once
    },
    setSiteType: (state, actions) => {
      state.siteType = { ...state.siteType, ...actions.payload }; // act as cdp cache to send event only once
    },
    setGluedinDataByRailId: (state, actions) => {
      state.allGluedinData = { ...state.allGluedinData, ...actions.payload }; // act as cdp cache to send event only once
    },
    setSongDropData: (state, actions) => {
      state.songDropData = { ...state.songDropData, ...actions.payload }; // act as cdp cache to send event only once
    },
    setAfterLoginAction: (state, actions) => {
      state.afterLoginAction = actions.payload; // act as cdp cache to send event only once
    },
    setRewardDetails: (state, actions) => {
      state.rewardDetails = actions.payload; // act as cdp cache to send event only once
    },
    setCoinAnimation: (state, actions) => {
      state.showCoin = actions.payload; // act as cdp cache to send event only once
    },
    setClaimFormWindowStatus: (state, actions) => {
      state.isClaimFormWindowActive = actions.payload; // act as cdp cache to send event only once
    },
  },
});

// Extract the action creators generated by `createSlice`
export const {
  setCoinAnimation,
  setRewardDetails,
  setAfterLoginAction,
  setSongDropData,
  setGluedinDataByRailId,
  setSiteType,
  toggleLoading,
  setIpDetails,
  setLanguage,
  setHomePageDetails,
  setAvatarList,
  setfaqDetails,
  setTierDetails,
  setDefaultAddress,
  setPlayVedio,
  showTourGuide,
  setClaimFormWindowStatus,
} = loadingSlice.actions;

// Export the reducer function generated by `createSlice`
export default loadingSlice.reducer;
