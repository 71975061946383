import { createContext, useState, useCallback, useEffect } from "react";
import UniqueCodeModal from "../appForm/UniqueCode";
import ReferAFriendForm from "../appForm/ReferFriendForm";
import Startquiz from "../appForm/Startquiz";
import SuccessModel from "../../modals/api_response_model";
import ExitModel from "../../modals/exit_modal";
import ContactUs from "../../modals/contact_us";
import { useDispatch } from "react-redux";
import { setCoinAnimation } from "../../../store/slices/common-slice";
import { getWalletList } from "../../../utils/axios-helper/api-hander";
import useLoader from "../../../utils/hooks/use-loader";
import useAuth from "../../../utils/hooks/use-auth";

export const PopupContext = createContext();

// ... other imports

export const PopupProvider = ({ children }) => {
  const dispatch = useDispatch();
  const [showPopup, setShowPopup] = useState({ type: "", show: false });
  const [modeldata, setModeldata] = useState("");
  const { toggleLoader } = useLoader();
  const { isLoggedIn } = useAuth();
  const openPopup = useCallback(
    (type, action, params) => {
      setShowPopup({
        type,
        show: action !== undefined ? action : !showPopup.show,
        params: params,
      });
      setModeldata(params);
    },
    [showPopup]
  );

  const closePopup = useCallback(() => {
    if (modeldata && modeldata.showAnimation) {
      dispatch(setCoinAnimation(true));
      setTimeout(() => {
        dispatch(setCoinAnimation(false));
      }, 2500);
    }
    setShowPopup({ type: "", show: false, params: "" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    isLoggedIn &&
      showPopup &&
      showPopup?.show &&
      getWalletList(toggleLoader, dispatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showPopup?.show]);

  return (
    <>
      <PopupContext.Provider value={{ openPopup }}>
        {children}
        {showPopup.type === "unique_code" && (
          <UniqueCodeModal
            show={showPopup.show}
            onClose={closePopup}
            type={"model"}
            params={showPopup.params}
          />
        )}
        {showPopup.type === "refer" && (
          <ReferAFriendForm
            show={showPopup.show}
            onClose={closePopup}
            type={"refer_code"}
          />
        )}
        {showPopup.type === "refer_friend" && (
          <ReferAFriendForm
            show={showPopup.show}
            onClose={closePopup}
            type={"refer_number"}
            getReferralList={() => {}}
          />
        )}
        {showPopup.type === "start_quiz" && (
          <Startquiz
            show={showPopup.show}
            onClose={closePopup}
            params={showPopup.params}
          />
        )}

        {showPopup.type === "success_model" && (
          <SuccessModel
            show={showPopup.show}
            onClose={closePopup}
            {...showPopup.params}
          />
        )}

        {showPopup.type === "logout_model" && (
          <ExitModel
            show={showPopup.show}
            onClose={closePopup}
            {...showPopup.params}
          />
        )}

        {showPopup.type === "contact_us" && (
          <ContactUs
            show={showPopup.show}
            onClose={closePopup}
            type={"contact_us"}
          />
        )}
      </PopupContext.Provider>
    </>
  );
};
