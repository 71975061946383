// LanguageSwitcher.js
import React, { useState, useEffect } from "react";
import useTranslation from "../../utils/hooks/use-translation";
import { LangCDP } from "../../utils/cds/cdsHelper";
import { isTamil } from "../../utils/helpers";
import Cookies from "js-cookie";

function LanguageSwitcher() {
  let siteType = isTamil() ? "tamil" : "bharat";
  const { currentLanguage, changeLanguage } = useTranslation();
  Cookies.set("language", currentLanguage);
  const [value, setvalue] = useState(currentLanguage);

  const getClassByname = () => {
    let className = "en_global";
    if (value === "hi") {
      //for hindi
      className = "hi_global";
    } else if (value === "ta") {
      // for tamil
      className = "ta_global";
    }
    return className;
  };

  // useEffect(() => {
  //   // Set the className of the body element
  //   document.body.className = getClassByname();
  //   // Clean up the className when the component unmounts
  //   return () => {
  //     document.body.className = '';
  //   };
  // }, [value]); // Empty dependency array to run this effect only once

  useEffect(() => {
    // Get the current classes of the body element
    const currentClasses = document.body.classList;

    // Set the className of the body element based on the result of getClassByname()
    const className = getClassByname();

    if (currentClasses) {
      // If body has classList, add the new class without removing existing classes
      currentClasses.add(className);
    } else {
      // If body does not have classList, append the new class to the existing classes
      document.body.className += " " + className;
    }

    // Clean up the className when the component unmounts
    return () => {
      if (currentClasses) {
        // If body has classList, remove only the newly added class
        currentClasses.remove(className);
      } else {
        // If body does not have classList, remove only the newly added class from className
        document.body.className = document.body.className
          .replace(className, "")
          .trim();
      }
    };
  }, [value]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleLanguageChange = (value) => {
    if (value) {
      const newLanguage = value;
      changeLanguage(newLanguage);
      LangCDP(value);
      setvalue(value);
      // window.location.reload()
    } else {
      setvalue("");
    }
    Cookies.set("language", value);

    if (siteType === "tamil") {
      if (value === "en") {
        window.dataLayer.push({ event: "CSTamil24English" });
      } else if (value === "ta") {
        window.dataLayer.push({ event: "CSTamil24Tamil" });
      }
    } else {
      if (value === "en") {
        window.dataLayer.push({ event: "CSBharat24english" });
      } else if (value === "hi") {
        window.dataLayer.push({ event: "CSBharat24hindi" });
      }
    }
  };

  return (
    <select
      className="form-select form-select-language change_language"
      value={value}
      onChange={(event) => handleLanguageChange(event.target.value)}
    >
      {siteType === "tamil" ? (
        <>
          <option value="en" className="CSTamil24English">
            English
          </option>
          <option value="ta" className="CSTamil24Tamil">
            தமிழ்
          </option>
        </>
      ) : (
        <>
          <option value="en" className="CSBharat24english">
            English
          </option>
          <option value="hi" className="CSBharat24hindi">
            हिंदी
          </option>
        </>
      )}
      {/* <option value="te">Te</option>
      <option value="bn">Bn</option> */}
    </select>
  );
}

export default LanguageSwitcher;
