import React from "react";
import { Modal, Button } from "react-bootstrap";

function AppModal({
  children,
  show,
  onClose,
  heading = "",
  subHeading = "",
  headerClass = "",
  headingClass = "",
  bodyClass = "",
  centered = true,
  hideClose = false,
  closeLabel = "Close",
  contentClassName = "",
  size = "",
  gaClass,
  ...rest
}) {
  return (
    <Modal
      show={show}
      onHide={onClose}
      centered={centered}
      {...rest}
      contentClassName={`${contentClassName} ${gaClass}`}
      id={gaClass}
      size={size}
    >
      <Modal.Header className={`border-0 ${headerClass}`}>
        {heading && (
          <Modal.Title as="h3" className={`${headingClass}`}>
            {heading}
          </Modal.Title>
        )}
        {!hideClose && (
          <Button
            variant=""
            className="close"
            onClick={onClose}
            aria-label={closeLabel}
          >
            <span className="icon-Cross-01"></span>
          </Button>
        )}

        {subHeading && <h6>{subHeading}</h6>}
      </Modal.Header>

      <Modal.Body className={`${bodyClass}`}>{children}</Modal.Body>
    </Modal>
  );
}

export default AppModal;
